<template>
  <div class="TJDetailMenu">
    <div
      :class="[
        {
          'rightBorder': item.rightBorder == false,
          'bottomBorder': item.bottomBorder == false,
          'absorbance': item.type != null
        },
        'box',
      ]"
      v-for="(item, index) of dataList"
      :key="index"
      :style="'width:' + item.width"
    >
      <p v-if="item.status">{{ item.name }}({{ item.status }}):</p>
      <p v-else>{{ item.name }}:</p>
      <p v-if="item.type" class="absorbance"> 
        <!-- 吸光度 -->
        <absorbance :value="item.value" :type="item.type"></absorbance>
      </p>
      <p v-else>{{ item.value == null ? '-' : item.value }}</p>
    </div>
  </div>
</template> 
<script>
import absorbance from './absorbance.vue'
export default {
  props: ['dataList'],
  components: {absorbance},
}
</script>
<style lang="scss" scoped>
.TJDetailMenu {
  min-height: px(90);
  background-color: #fff;
  border-radius: 5px;
  padding: 0 px(8);
  display: flex;
  margin-bottom: px(20);
  font-size: px(20);
  flex-wrap: wrap;
  box-sizing: border-box;
  .box {
    width: 33%;
    border-right: 1px solid #ebf0f6;
    box-sizing: border-box;
    padding: px(10) 0 0 px(10);
    height: px(80);
    border-bottom: 1px solid #ebf0f6;
    p:nth-child(1) {
      color: #999999;
      margin-bottom: px(6);
    }
    p:nth-child(2) {
      height: px(19);
      color: #333333;
    }
  }
  .box:nth-last-child(1) {
    border-right: none;
  }

  .bottomBorder {
    border-bottom: none !important;
  }
  .rightBorder {
    border-right: none;
  }
  .absorbance {
    height: px(100);
  }
}


</style>