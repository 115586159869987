<template>
  <div class="GoCompositionEchart">
    <div :id="id" class="echarts"></div>
  </div>
</template>
<script>
import echartsMixins from "@/assets/js/echartsMixins.js"

export default {
  props: ['id', 'ehartData', 'colorList','total','dw'],
  mixins: [echartsMixins],

  data() {
    return {
      myChart: '',
    }
  },
  watch: {
    ehartData() {
      this.initEcharts()
    },
  },
  mounted() {
    this.myChart = this.$echarts.init(document.getElementById(this.id))
    this.initEcharts()
  },
  computed: {
    digit() {
      if(this.dw == '元'){
        return 2
      }
      return 0
    }
  },
  methods: {
    // 刷新组件
    handlerResize(){
      this.myChart.resize()
    },
    initEcharts() {
      //配置扇形图
      let that = this;
      let titleText = this.$tool.moneyFormatter(Number(this.total),this.digit)
      // let titleText = '4,115,781.22'
      let titleNum = titleText.length
      let titleSize = titleNum<=9?32:(32-2.5*(titleNum-9))

      var option = {
        title: {
          // text: this.total.toFixed(2),
          text: titleText,
          subtext: '总计('+this.dw+')',
          textStyle: {
            color: '#333333',
            fontSize: titleSize,
          },
          subtextStyle: {
            fontSize: 14,
            color: ['#666666'],
          },
          x: 'center',
          y: 'center',
        },
        tooltip: {
          trigger: 'item',
          // formatter: '{b} : {c} ({d}%)', // <br/>换行
          formatter: function(data){
            return `${data.data.name} : ${data.data.value} (${(data.data.value/Number(that.total)*100).toFixed(2)}%)`
          },
         
        },
        series: [
          {
            name: '饼图',
            type: 'pie',
            radius: [78, 130],
            center: ['50%', '50%'],
            // roseType: 'angle',
            itemStyle: {
              borderColor: '#fff',
              borderWidth: '2',
              color: (params) => {
                return this.colorList[params.dataIndex]
              },
              
            },
            label: {
              show: true,
              position: 'outer',
              alignTo: 'labelLine',
              // ·圆点
              backgroundColor: 'auto', //圆点颜色，auto：映射的系列色
              height: 0,
              // width: 20,
              lineHeight: 0,
              // height,width.lineHeight必须为0
              distanceToLabelLine: 0, //圆点到labelline距离
              borderRadius: 2.5,
              padding: [2.5, -2.5, 2.5, -2.5],
              formatter: (params) => {
                // let name = params.data.name,
                //   value = params.data.value
                // return '{a|' + name + '}\n{b|' + value + '}'
                let name = params.data.name,
                  value = that.$tool.moneyFormatter(params.data.value,that.digit) ,
                  percent = (params.data.value/Number(that.total)*100).toFixed(2)+'%'
                // return '{a|'+name+'}{a|'+'('+percent+')'+'}\n{b|'+value+'}'
                return '{a|'+name+'}\n{b|'+value+'}{b|'+'('+percent+')'+'}'
              },
              rich: {
                a: {
                  padding: [-25, 0, 0, 0],
                  color: '#666666',
                  fontSize: '14',
                },
                b: {
                  padding: [-70, 0, 0, 0],
                  fontSize: '14',
                  fontWeight: 'Bold',
                },
              },
            },
            labelLine: {
              lineStyle: {
                width: 1,
                type: 'dashed',
              },
            },
            // data: this.ehartData,
            data: this.ehartData.map((item,index)=> {
              item.label = { 
                color: this.colorList[index]
              }
              return item;
            }),

          },
          {
            name: '饼图内部%数字',
            type: 'pie',
            radius: [78, 130],
            center: ['50%', '50%'],
            // roseType: 'angle',
            itemStyle: {
              borderColor: '#fff',
              borderWidth: '2',
              color: (params) => {
                return this.colorList[params.dataIndex]
              },
            },
            label: {
              show: false,
              position: 'inner',
              padding: [2.5, -2.5, 2.5, -2.5],
              // formatter: '{a|{d}%}',
                   // rich: {
              //   a: {
              //     padding: [0, 0, 0, 0],
              //     textBorderColor: "#fff",
              //     textBorderWidth: 2,
              //     fontSize: '20',
              //     fontWeight: 'Bold',
              //   },
              // },
              formatter: function(data){
                return (data.data.value/Number(that.total)*100).toFixed(2)+'%'
              },
              textStyle: {
                padding: [0, 0, 0, 0],
                textBorderColor: "#fff",
                textBorderWidth: 2,
                fontSize: '20',
                fontWeight: 'Bold',
              },
         
            },
            labelLine: {
              show: true,
            },
            data: this.ehartData,
          },
        ],
      }
      this.myChart.setOption(option)
    },
  },
}
</script>
<style lang="scss" scoped>
.GoCompositionEchart {
  width: 100%;
  .echarts {
    width: 100%;
    height: px(600);
  }
}
</style>
