<template>
  <div class="TJDetailTitle">
    {{ titleData }}
  </div>
</template>
<script>
export default {
  props: ['titleData'],
}
</script>
<style lang="scss" scoped>
.TJDetailTitle {
  height: 2.385417vw;
  background-color: transparent;
  display: flex;
  margin-bottom: px(5);
  font-weight: bold;
  color: #111111;
}
</style>